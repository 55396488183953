/* 入场动画开始 */
.translateX-enter {
  transform: translateX(100%);
  opacity: 0.8;
  /* will-change: transform; */
}

/*入场动画过程*/
.translateX-enter-active {
  transform: translateX(0);
  transition: transform 0.3s ease;
}

/*入场动画结束*/
.translateX-enter-done {
  transform: translateX(0);
}

/*离场动画开始*/
.translateX-exit {
  transform: translateX(0);
  /* will-change: transform; */
}

/*离场动画过程*/
.translateX-exit-active {
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  opacity: 0.8;
}

/*离场动画结束*/
/* .translateX-exit-done {
  transform: translateX(-100%);
} */

/*页面第一次加载时的开始状态*/
/* .translateX-appear {
  transform: translateX(100%);
} */

/*页面第一次加载时的动画过程*/
/* .translateX-appear-active {
  transform: translateX(0%);
  transition: transform 0.3s ease;
} */

/* 反向back */
/*入场动画开始*/
.reTranslateX-enter {
  transform: translateX(-100%);
  opacity: 0.8;
  /* will-change: transform; */
}

/*入场动画过程*/
.reTranslateX-enter-active {
  transform: translateX(0%);
  transition: transform 0.3s ease;
}

/*入场动画结束*/
/* .reTranslateX-enter-done {
  transform: translateX(0%);
} */

/*离场动画开始*/
.reTranslateX-exit {
  /* will-change: transform; */
  transform: translateX(0%);
}

/*离场动画过程*/
.reTranslateX-exit-active {
  transform: translateX(100%);
  transition: transform 0.3s ease;
  opacity: 0.8;
}

/*离场动画结束*/
/* .reTranslateX-exit-done {
  transform: translateX(100%);
} */

/*页面第一次加载时的开始状态*/
/* .reTranslateX-appear {
  transform: translateX(0);
} */

/*页面第一次加载时的动画过程*/
/* .reTranslateX-appear-active {
  transform: translateX(100%);
  transition: transform 0.3s ease;
} */

/* 入场动画开始 */
.fade-enter {
  opacity: 0.8;
  /* will-change: transform; */
}

/*入场动画过程*/
.fade-enter-active {
  transition: opacity 0.1s ease-in;
}

/*入场动画结束*/
.fade-enter-done {
  opacity: 1;
}

/*离场动画开始*/
/* .fade-exit {
  will-change: transform;
} */

/*离场动画过程*/
.fade-exit-active {
  /* transition: opacity 0s ease; */
  opacity: 0;
}
